/**
 * 判断是否登录
 * @returns 
 */
const getLoginType = () => {
        return new Promise((reslove, reject) => {
            let loginType = localStorage.getItem("userinfo")
            if (loginType) {
                reslove(true)
            } else {
                reject(false)
            }
        })
    }
    /**
     * 写入H5缓存
     * @param {*} key 
     * @param {*} value 
     * @returns 
     */
const setLoaclStorage = (key, value) => {
        window.localStorage.setItem(key, value)
    }
    /**
     * 读取本地缓存
     * @param {*} key 
     * @returns 
     */
const getLoaclStorage = (key) => {
        return window.localStorage.getItem(key)
    }
    /**
     * 验证手机号
     * @param {*} phone 
     * @returns 
     */
const checkPhone = (phone) => {

    if (!(/^1[3456789]\d{9}$/.test(phone))) {
        return false
    } else {
        return true
    }

}

const getIndex = (array, item) => {
    for (let i = 0; i < array.length; i++) {
        let key = array[i].locationId
        if (key == item) {
            return i
        }
    }
}
module.exports = {
    getLoginType,
    setLoaclStorage,
    getLoaclStorage,
    checkPhone,
    getIndex
}